import React from 'react'

const testimonials = [
    {
        name: 'Catrin Gentile',
        title: 'I highly recommend Justian',
        text: "I had a fantastic experience with Justian Hanke. He went above and beyond to find me a fantastic property. He's 100% reliable and a pleasure to work with at all times. I highly recommend Justian as an estate agent!"
    },
    {
        name: 'Claudius Pleissner',
        title: 'Never pushy or hurried',
        text: "I was interested in buying a property on the north coast of the Dominican Republic and found a very competent and courteous agent in Justian Hanke. Being trilingual, he immediately understood what I was looking for and found several properties to view. He was always professional and never seemed pushy or rushed. I highly recommend his services."
    },
    {
        name: 'Manfred Armin Sendelbach',
        title: 'A German broker you can trust',
        text: "I recently bought a property in Sosua as a return on investment through Mr. Justian Hanke and I am very satisfied. From customer service to the purchase transaction, very professional. also the rental is taken care of. A german realtor you can trust. "
    },
]

export default function Testimonials() {
    return (
        <div className='bg-gray-100 py-20'>
            <div className="flex flex-col max-w-7xl w-full mx-auto justify-start my-2 container">
                <div className="text-menzBlue-500 mx-4 font-trajanPro text-4xl gap-4 lg:text-5xl z-10">
                </div>
                <div className="flex flex-col lg:flex-row gap-4">
                    {testimonials.map((testimonial, index) => (
                        <div className="flex flex-1 flex-col  items-center" key={`testimonial-${index}`}>
                            <div className="flex items-center mb-5">
                                {[1, 2, 3, 4, 5].map((element) => (
                                    <div key={`star-${index}-${element}`}>
                                    <svg
                                        aria-hidden="true"
                                        className="w-5 h-5 text-teracotta-500"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                        
                                    >
                                        <title>First star</title>
                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                    </svg>
                                    </div>
                                ))}
                            </div>
                            <div className="font-bold">{testimonial.title}</div>
                            <blockquote className='text-center'>
                                {testimonial.text}
                            </blockquote>
                            <div>
                                <div
                                    className="text-gray-800 italic text-xs"
                                >
                                    {testimonial.name}
                                </div>
                            </div>
                        </div>

                    ))}

                </div>
            </div>
        </div>
    )
}
