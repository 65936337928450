import React from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useLoadScript } from "@react-google-maps/api";
import { MarkerF } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '100%'
};


export default function Map() {
    const markerCoordinates = { lat: 19.769182, lng: -70.512009 };
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    });


    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={markerCoordinates}
            zoom={15}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <MarkerF position={markerCoordinates} />
            { /* Child components, such as markers, info windows, etc. */}
        </GoogleMap>
    ) : <></>
}