import React, { useEffect, useState } from 'react'

import Header from '@/blocks/Header'
import Animation from '@/blocks/Animation'
import Hero from '@/blocks/Hero'
import Rooms from '@/blocks/Rooms'
import Footer from '@/blocks/Footer'
import Testimonials from '@/blocks/Testimonials'
import Team from '@/blocks/Team'
import WhyLocation from '@/blocks/WhyLocation'
import CTATour from '@/blocks/CTATour'
import FAQ from '@/blocks/FAQ'
import Location from '@/blocks/Location'
import Roadmap from '@/blocks/Roadmap'
import BusinessModel from '@/blocks/BusinessModel'
import Timeline from '@/blocks/Timeline'
import CTAExpose from '@/blocks/CTAExpose'

export default function index() {

    return (
        <div className=''>
            <Hero />
            <WhyLocation />
            <Animation />
            <Rooms />
            <BusinessModel />
            <Location />
            <CTAExpose />
            <Roadmap />
            <Team />
            <Testimonials />
            <Timeline />
            <CTATour />
            <FAQ />
        </div>
    )
}
