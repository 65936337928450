import Image from "next/image";
import { HiCheck } from "react-icons/hi2";

import constructionRecent from "@/assets/img/construction-site-recent.jpeg";
import construction1 from "@/assets/img/construction-1.jpeg";
import appartmentSales from "@/assets/img/appartment_sales.jpg";
import grandOpening from "@/assets/img/grand_opening.jpg";
import render from "@/assets/img/render.jpeg";
import { useEffect, useState } from "react";

const steps = [
  {
    name: "Start of planning phase",
    date: 'October 2020',
    description: (
      <span>
        The planning phase of our project "Rizz Suites" includes the design and planning of an apartment building. For this we work together with an architect's office and an international construction company. Our basic ideas are included in the planning to realize a successful project.
      </span>
    ),
    image: render,
    status: "complete",
  },
  {
    name: "Start of Construction",
    date: 'March 2021',
    description: (
      <span>
        The construction phase of our dream project has begun in 2021. We have successfully completed the planning and the first stones have been laid. Our goal is to realize our dream and set up a successful project. We are very excited and motivated to make this project a reality.
      </span>
    ),
    image: construction1,
    status: "complete",
  },
  {
    name: "Apartment Sales",
    date: 'May 2023',
    description: (
      <span>
        now a long-awaited construction project is 95% complete and we are focusing on details and design to provide a unique experience for visitors. The project demonstrates progress and commitment to the future and will attract visitors from around the world.
      </span>
    ),
    image: appartmentSales,
    status: "complete",
  },
  {
    name: "Construction finished",
    date: 'November 2023',
    description: (
      <span>
        work is expected to be completed in November.
        Once the construction site is completed in November, we will be proud to present the finished result to our customers and partners. We are confident that the completed construction site will meet their needs and expectations.
      </span>
    ),
    image: constructionRecent,
    status: "complete",
  },
  {
    name: "Grand Opening",
    date: '2024',
    description: (
      <span>
        In 2024, a long-awaited project will finally open. Months of planning and hard work have gone into the impressive project that will leave visitors speechless when it opens.
      </span>
    ),
    image: grandOpening,
    status: "current",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Roadmap() {
  const [currentStep, setCurrentStep] = useState(0);


  return (
    <div className="pt-20">
      <div className="w-screen overflowy-hidden pb-20 text-gray-700">
        <div className="container max-w-2xl xl:col-span-2">
          <h2 className="text-gray-800 h1 tracking-tight sm:text-6xl font-trajanPro">
            Project Status
          </h2>
          {/* <div className="mt-6 p leading-8 ">
                    </div> */}
        </div>
        <div className="flex justify-between space-x-12 mt-12 hidden">
          <div aria-label="Progress" className="">
            <ol role="list" className="overflow-hidden">
              {steps.map((step, stepIdx) => (
                <li
                  key={step.name}
                  className={classNames(
                    stepIdx !== steps.length - 1 ? "pb-6" : "",
                    "relative"
                  )}
                >
                  {step.status === "complete" ? (
                    <>
                      {stepIdx !== steps.length - 1 ? (
                        <div
                          className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-teracotta-500"
                          aria-hidden="true"
                        />
                      ) : null}
                      <div className="group relative flex items-start">
                        <div className="flex h-9 items-center">
                          <div className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-teracotta-500 group-hover:bg-teracotta-600">
                            <HiCheck
                              className="h-5 w-5 text-white"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                        <button
                          onClick={() => setCurrentStep(stepIdx)}
                          className={`ml-4 flex min-w-0 flex-col ${currentStep === stepIdx
                            ? "bg-gray-100 hover:bg-gray-200"
                            : "hover:bg-gray-100"
                            } hover:bg-gray-100 duration-300 rounded-lg px-4 py-2 transform`}
                        >
                          <span className="text-base font-medium">
                            {step.name}
                          </span>
                          <span className="text-sm text-center">
                            {step.date}
                          </span>
                          <span className="text-sm text-gray-500">
                            {step.description}
                          </span>
                        </button>
                      </div>
                    </>
                  ) : step.status === "current" ? (
                    <>
                      {stepIdx !== steps.length - 1 ? (
                        <div
                          className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                          aria-hidden="true"
                        />
                      ) : null}
                      <div
                        className="group relative flex items-start"
                        aria-current="step"
                      >
                        <span
                          className="flex h-9 items-center"
                          aria-hidden="true"
                        >
                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-teracotta-500 bg-white">
                            <span className="h-2.5 w-2.5 rounded-full bg-teracotta-500" />
                          </span>
                        </span>
                        <button
                          onClick={() => setCurrentStep(stepIdx)}
                          className={`ml-4 flex min-w-0 flex-col items-center ${currentStep === stepIdx
                            ? "bg-gray-100 hover:bg-gray-200"
                            : "hover:bg-gray-100"
                            } hover:bg-gray-100 duration-300 rounded-lg px-4 py-2 transform`}
                        >
                          <span className="text-base font-medium text-teracotta-500">
                            {step.name}
                          </span>
                          <span className="text-sm text-center text-teracotta-500">
                            {step.date}
                          </span>
                          <span className="text-sm text-gray-500">
                            {step.description}
                          </span>
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      {stepIdx !== steps.length - 1 ? (
                        <div
                          className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                          aria-hidden="true"
                        />
                      ) : null}
                      <div className="group relative flex items-start">
                        <span
                          className="flex h-9 items-center"
                          aria-hidden="true"
                        >
                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                            <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                          </span>
                        </span>
                        <button
                          onClick={() => setCurrentStep(stepIdx)}
                          className={`ml-4 flex min-w-0 flex-col ${currentStep === stepIdx
                            ? "bg-gray-100 hover:bg-gray-200"
                            : "hover:bg-gray-100"
                            } hover:bg-gray-100 duration-300 rounded-lg px-4 py-2 transform`}
                        >
                          <span className="text-base font-medium text-gray-500">
                            {step.name}
                          </span>
                          <span className="text-sm text-center text-gray-500">
                            {step.date}
                          </span>
                          <span className="text-sm text-gray-500">
                            {step.description}
                          </span>
                        </button>
                      </div>
                    </>
                  )}
                </li>
              ))}
            </ol>
          </div>
        </div>


        <div className="flex flex-col lg:flex-row items-center mt-12 w-screen lg:w-[105vw] lg:ml-[-2.5vw]">
          {steps.map((step, stepIdx) => {
            return (
              <div className="group width-trans" key={`substep-${stepIdx}`}>
                <div
                  className={`flex justify-center items-end shadow-xl relative ${step.status === "current"
                    ? "border-teracotta-500 border-8 lg:h-[30rem] h-[30rem] z-[10]"
                    : "lg:h-[28rem] h-[10rem]"
                    }  -skew-y-6 lg:-skew-y-0 lg:-skew-x-6  h-trans overflow-hidden bg-red-100 w-screen hover:z-[12] lg:w-auto hover:scale-105 duration-300 transform`}
                >
                  {step.status === "upcoming" ? (
                    <div className={`absolute w-full h-full ${stepIdx === 3 ? "bg-rizzBlue-700" : ""} ${stepIdx === 4 ? "bg-rizzBlue-800" : ""}`}>
                      <img
                        src={"/icon-white.svg"}
                        style={{ transform: "scaleX(-1)" }}
                        className="hidden lg:block absolute lg:bottom-8 opacity-60 left-9 w-full lg:h-1/2"
                      ></img>
                    </div>
                  ) : (
                    <>
                      <Image
                        className="skew-x-6 fill-skew hidden lg:block"
                        style={{ objectFit: "cover" }}
                        src={step.image}
                        alt="progress update of the project"
                      />
                      <Image
                        className="skew-y-6 fill-skew-sm lg:hidden"
                        style={{ objectFit: "cover" }}
                        src={step.image}
                        alt="progress update of the project"
                      /></>
                  )}

                  <div className={`absolute w-full h-[40%] bottom-0 left-0 bg-gradient-to-t from-black/70`}></div>

                  {step.status !== "current" ? (
                    <div className={`absolute w-full h-full ${stepIdx === 0 ? "bg-black/40" : ""} ${stepIdx === 1 ? "bg-black/20" : ""}`}></div>
                  ) : (
                    ""
                  )}

                  <div className="lg:skew-y-0 skew-y-6 lg:skew-x-6 text-white pb-4">
                    {step.status === "complete" ? (
                      <div className=" relative flex flex-col items-center justify-center flex-1">
                        <span className="flex h-9 items-center justify-center">
                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-teracotta-500 group-hover:bg-teracotta-600 ">
                            <HiCheck
                              className="h-5 w-5 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </span>
                        <button
                          onClick={() => setCurrentStep(stepIdx)}
                          className={`ml-4 flex min-w-0 flex-col items-center duration-300 rounded-lg px-4 py-2 transform max-w-[95%]`}
                        >
                          <span className="text-base font-medium">{step.name}</span>
                          <span className="text-sm text-center font-medium">{step.date}</span>
                          <span className="text-sm text-gray-200 max-h-0 transform duration-1000 overflow-hidden group-hover:max-h-0 lg:mx-16">
                            {step.description}
                          </span>
                        </button>
                      </div>
                    ) : step.status === "current" ? (
                      <div className=" relative flex flex-col items-center justify-center flex-1">
                        <span className="flex h-9 items-center" aria-hidden="true">
                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-teracotta-500 bg-white">
                            <span className="h-2.5 w-2.5 rounded-full bg-teracotta-500" />
                          </span>
                        </span>
                        <button
                          onClick={() => setCurrentStep(stepIdx)}
                          className={`ml-4 flex min-w-0 flex-col items-center duration-300 rounded-lg px-4 py-2 transform`}
                        >
                          <span className="text-base font-medium text-teracotta-200">
                            {step.name}
                          </span>
                          <span className="text-sm text-center text-teracotta-200">
                            {step.date}
                          </span>
                          <span className="text-sm text-gray-200 max-h-0 transform duration-1000 overflow-hidden group-hover:max-h-0 lg:mx-16">
                            {step.description}
                          </span>
                        </button>
                      </div>
                    ) : (
                      <div className=" relative flex flex-col items-center justify-center flex-1">
                        <span className="flex h-9 items-center" aria-hidden="true">
                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2">
                            <span className="h-2.5 w-2.5 rounded-full bg-transparent" />
                          </span>
                        </span>
                        <button
                          onClick={() => setCurrentStep(stepIdx)}
                          className={`ml-4 flex min-w-0 flex-col items-center duration-300 rounded-lg px-4 py-2 transform max-w-[95%]`}
                        >
                          <span className="text-base font-medium text-gray-200">
                            {step.name}
                          </span>
                          <span className="text-sm text-center text-gray-200">
                            {step.date}
                          </span>
                          <span className="text-sm text-gray-200 max-h-0 transform duration-1000 overflow-hidden group-hover:max-h-0 lg:mx-16">
                            {step.description}
                          </span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>


              </div>
            );
          })}
        </div>

        <div className="flex flex-col lg:flex-row items-start mt-4 hidden">
          {steps.map((step, stepIdx) =>
            step.status === "complete" ? (
              <div key={`roadmap-item-${stepIdx}`} className="group relative flex flex-col items-center justify-center flex-1">
                <div className="flex h-9 items-center justify-center">
                  <div className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-teracotta-500 group-hover:bg-teracotta-600">
                    <HiCheck
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <button
                  onClick={() => setCurrentStep(stepIdx)}
                  className={`ml-4 flex min-w-0 flex-col ${currentStep === stepIdx
                    ? "bg-gray-100 hover:bg-gray-200"
                    : "hover:bg-gray-100"
                    } items-center hover:bg-gray-100 duration-300 rounded-lg px-4 py-2 transform `}
                >
                  <span className="text-base font-medium">{step.name}</span>
                  <span className="text-sm text-gray-500">
                    {step.description}
                  </span>
                </button>
              </div>
            ) : step.status === "current" ? (
              <div key={`roadmap-item-${stepIdx}`} className="group relative flex flex-col items-center justify-center flex-1">
                <span className="flex h-9 items-center" aria-hidden="true">
                  <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-teracotta-500 bg-white">
                    <span className="h-2.5 w-2.5 rounded-full bg-teracotta-500" />
                  </span>
                </span>
                <button
                  onClick={() => setCurrentStep(stepIdx)}
                  className={`ml-4 flex min-w-0 flex-col ${currentStep === stepIdx
                    ? "bg-gray-100 hover:bg-gray-200"
                    : "hover:bg-gray-100"
                    } hover:bg-gray-100 duration-300 rounded-lg px-4 py-2 transform`}
                >
                  <span className="text-base font-medium text-teracotta-600">
                    {step.name}
                  </span>
                  <span className="text-sm text-gray-500">
                    {step.description}
                  </span>
                </button>
              </div>
            ) : (
              <div key={`roadmap-item-${stepIdx}`} className="group relative flex flex-col items-center justify-center flex-1">
                <span className="flex h-9 items-center" aria-hidden="true">
                  <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                    <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                  </span>
                </span>
                <button
                  onClick={() => setCurrentStep(stepIdx)}
                  className={`ml-4 flex min-w-0 flex-col items-center ${currentStep === stepIdx
                    ? "bg-gray-100 hover:bg-gray-200"
                    : "hover:bg-gray-100"
                    } hover:bg-gray-100 duration-300 rounded-lg px-4 py-2 transform`}
                >
                  <span className="text-base font-medium text-gray-500">
                    {step.name}
                  </span>
                  <span className="text-sm text-gray-500">
                    {step.description}
                  </span>
                </button>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}
