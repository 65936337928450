import { FaUmbrellaBeach } from "react-icons/fa"
import { HiArrowRight, HiOutlineArrowTrendingDown, HiOutlineUserGroup } from "react-icons/hi2"
import { AiOutlineSafety } from "react-icons/ai"
import Image from "next/image"
import outline from '@/assets/img/outline.png'


const stats = [
    { id: 1, name: 'lower property prices (compared to Florida)', icon: HiOutlineArrowTrendingDown, highlightText: '62%' },
    { id: 2, name: 'Stable political & economic environment', icon: AiOutlineSafety },
    { id: 3, name: 'yearly growth of the local tourism industry', icon: HiOutlineUserGroup, highlightText: '15%' },
    { id: 4, name: 'Warm climate, beautiful surroundings', icon: FaUmbrellaBeach },
]

export default function WhyLocation() {
    return (
        <div className="bg-white py-16 ">
            <div className="container">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
                    <div>

                        <div className="flex flex-col gap-4 text-left my-6">
                            <h2 className="h2 text-gray-800">The Dominican Republic: <br /> A Prime Investment Locale</h2>
                            <p className="max-w-4xl mt-4">
                                Compared to the United States, the affordability of property in the Dominican Republic presents a compelling advantage, allowing investors to acquire assets at lower prices.
                                <br /><br />
                                The country's booming tourism industry generates high rental yields and consistent cash flow. With a stable political climate and straightforward property ownership laws, investing in Dominican real estate is hassle-free.
                                <br /><br />
                                Moreover, the Dominican Republic's stunning beaches, tropical climate, and vibrant culture make it a sought-after vacation and retirement destination, ensuring a steady demand for rental properties.
                            </p>
                        </div>
                        <div>
                            <button onClick={() => {
                                const el = document.getElementById('ctaExpose')
                                el.scrollIntoView({ behavior: 'smooth' })
                            }} className="btn-primary flex gap-2 items-center">
                                <span>
                                    Request more info
                                </span>
                                <HiArrowRight className="ml-2 h-4 w-4" />
                            </button>
                        </div>
                    </div>
                    <div>
                        <Image src={outline} alt="outline of the dominican republic" />
                    </div>
                </div>

                <dl className="mt-16 grid grid-cols-1 gap-4 overflow-hidden  text-center sm:grid-cols-2 lg:grid-cols-4">
                    {stats.map((stat) => (
                        <div key={`stat-${stat.id}`} className="flex flex-col bg-gray-400/5 p-8 gap-2 border border-teracotta-500">
                            <dt className="flex justify-center">
                                {stat.highlightText ? (
                                    <span className="text-teracotta-500 text-4xl font-medium">{stat.highlightText}</span>
                                ) : (
                                    <stat.icon className='h-10 w-10 text-teracotta-500' />
                                )}
                            </dt>
                            <dt className="text-sm font-semibold leading-6 text-gray-600">{stat.name}</dt>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    )
}
