import React, { useEffect, useRef } from "react";
// import RichTextParser from "@/components/RichTextParser";
import { HiOutlineArrowRight } from "react-icons/hi2";
import Link from "next/link";
import { rooms } from "@/assets/rooms";
import Image from "next/image";

import {
  PiCurrencyDollarSimpleBold,
  PiBedBold,
  PiCouchBold,
  PiShowerBold,
  PiSelectionBold,
} from "react-icons/pi";

const Rooms = () => {
  // const [isIntersecting, setIsIntersecting] = React.useState(false);
  const ref = useRef(null);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       setIsIntersecting(entry.isIntersecting);
  //     },
  //     // { rootMargin: "-200px" }
  //   );
  //   observer.observe(ref.current);
  // }, []);

  return (
    <div id="units" className="bg-gray-100 py-20">
      <div className="container my-8">
        <div className="flex flex-col gap-4 text-left my-6">
          <h2 className="h2">Available Units</h2>
          <div className="flex">
            {/* <LinkButton link={{ href: '/news' }} type={'tertiary'} >See All Categories</LinkButton> */}
          </div>
        </div>
        <div ref={ref} className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:-mx-[2rem]">
          {rooms?.map((room, id) => (
            <Link
              href={`/units/${room.slug}`}
              key={`room-${id}`}
              className={`${!room?.available ? 'opacity-70 pointer-events-none' : ''} relative flex flex-col flex-1 shadow-lg bg-rizzBlue-700/90 rounded overflow-hidden hover:scale-[1.01] hover:xl:scale-[1.02] transform duration-200`}
            >
              <div className="relative h-72 w-full">
                {!room?.available ? (
                  <div
                    className={`text-lg absolute right-40 rotate-[-20deg] top-40 z-40 ${room?.available <= 2 ? "bg-red-700" : "bg-rizzBlue-800"
                      } text-white inline-block px-4 py-3 rounded`}
                  >
                    SOLD OUT
                  </div>
                ) : ''}
                <div className="h-72"></div>
                <Image
                  alt="floor plan of the unit"
                  src={room?.image}
                  fill={true}
                  style={{ objectFit: "cover" }}
                  className=" bg-white"
                ></Image>
              </div>

              <div className="p-8 z-10 flex flex-col h-full justify-between">
                <div className="font-trajanPro text-white text-xl  md:text-3xl whitespace-nowrap mb-4">
                  {room?.name}
                </div>
                <div
                  className={`grid grid-cols-2 gap-2`}
                >
                  <div className="p-2 bg-rizzBlue-700">
                    <div className="absolute bottom-0 left-0 w-full h-full "></div>
                    <div className=" flex flex-col items-center gap-2">
                      <PiBedBold className="text-white h-7 w-7"></PiBedBold>
                      <div className="text-white font-bold">
                        {room?.bedrooms}
                      </div>
                    </div>
                  </div>

                  <div className="p-2 bg-rizzBlue-700">
                    <div className="absolute bottom-0 left-0 w-full h-full "></div>
                    <div className=" flex flex-col items-center gap-2">
                      <PiShowerBold className="text-white h-7 w-7"></PiShowerBold>
                      <div className="text-white font-bold">
                        {room?.bathrooms}
                      </div>
                    </div>
                  </div>

                  <div className="p-2 bg-rizzBlue-700">
                    <div className="absolute bottom-0 left-0 w-full h-full "></div>
                    <div className=" flex flex-col items-center gap-2">
                      <PiSelectionBold className="text-white h-7 w-7"></PiSelectionBold>
                      <div className="flex flex-row gap-2">
                        <div className="text-white text-sm font-bold">
                          {room?.areaF}ft²
                        </div>
                        <div className="text-white text-sm font-bold">
                          {room?.areaM}m²
                        </div>
                      </div>
                    </div>
                  </div>

                  {room?.livingroom > 0 ? (
                    <div className="p-2 bg-rizzBlue-700">
                      <div className="absolute bottom-0 left-0 w-full h-full "></div>
                      <div className=" flex flex-col items-center gap-2">
                        <PiCouchBold className="text-white h-7 w-7"></PiCouchBold>
                        <div className="text-white font-bold">
                          {room?.livingroom}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mt-8 text-white ">
                  {/* <span className="text-xs">starting from </span> */}
                  <span className="text-xl font-medium">${room?.price}</span>
                </div>
              </div>
              <div

                className=" group flex flex-row items-center text-teracotta-200 justify-between z-10 border-t border-teracotta-500 p-4 px-8"
              >
                <div>View Details </div>
                <div className="flex flex-row items-center gap-2">
                  {room?.available ? (
                    <>
                      <div
                        className={`text-sm ${room?.available <= 2 ? "bg-red-700" : "bg-rizzBlue-800"
                          } text-white inline-block px-2 py-1 rounded`}
                      >
                        {room?.available} available
                      </div>
                      <HiOutlineArrowRight className="w-6 h-6 text-teracotta-200 transform group-hover:translate-x-2 duration-150" />
                    </>
                  ) : ''}
                  {!room?.available ? (
                    <div
                      className={`text-sm ${room?.available <= 2 ? "bg-red-700" : "bg-rizzBlue-800"
                        } text-white inline-block px-2 py-1 rounded`}
                    >
                      SOLD OUT
                    </div>
                  ) : ''}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Rooms;
