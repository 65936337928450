import Image from 'next/image'
import { motion, useScroll, useTransform } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { FaCar, FaCocktail, FaLock, FaPowerOff, FaSun, FaSwimmingPool, FaUmbrellaBeach } from 'react-icons/fa'
import { BiRestaurant } from 'react-icons/bi'
import { HiOutlineLockClosed } from 'react-icons/hi2'
import { MdFitnessCenter } from 'react-icons/md'
import { useMediaQuery } from 'react-responsive'

export default function Animation() {
  const containerRef = useRef(null)
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start end', 'end start']
  })
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const xMoveBG = useTransform(scrollYProgress, [0, 0.7, 1], ['100%', '0%', '0%'])
  const xMoveText = useTransform(scrollYProgress, [0, 0.7, 1], ['0%', '20%', '20%'])

  const [isIntersecting, setIsIntersecting] = useState(false)

  const animationAnchorRef = useRef(null)

  // IOS Autoplay workaround
  useEffect(() => {
    window.addEventListener("touchstart", function () {
      var heroVideo = document.getElementById('animationVideo');
      heroVideo.play();
    }, { once: true });
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // if (entry.isIntersecting) {
        setIsIntersecting(entry.isIntersecting);
        // }
      },
      // { rootMargin: "-500px" }
    );
    observer.observe(animationAnchorRef.current);
  }, []);

  const amenities = [
    {
      label: '1130sqft Pool',
      icon: FaSwimmingPool
    },
    {
      label: '3767sqft Gym',
      icon: MdFitnessCenter
    },
    {
      label: 'Spa & Sauna',
      icon: FaUmbrellaBeach
    },
    {
      label: 'Deluxe Poolbar',
      icon: FaCocktail
    },
    {
      label: '24/7 Security',
      icon: HiOutlineLockClosed
    },
    {
      label: 'Eco Relax Areas',
      icon: FaSun
    },
    {
      label: 'Secure parking',
      icon: FaCar
    },
    {
      label: 'Backup Generator',
      icon: FaPowerOff
    }
  ]

  return (
    <section
      className={`flex min-h-screen flex-col items-center justify-between relative `}
    >
      <div ref={animationAnchorRef} className='absolute top-[80vh] md:top-[70vh]'></div>

      <div ref={containerRef} className=' w-full h-screen  relative'>
        <motion.div className="sticky top-0 object-cover overflow-hidden h-screen w-screen">
          {/* <img className='w-full' src="" alt="" /> */}
          <div className={`h-screen w-screen absolute object-cover -z-10 bg-black bg-opacity-20`}></div>
          <video
            id='animationVideo'
            className={'h-screen w-screen absolute overflow-hidden object-cover -z-20'}
            autoPlay={true}
            muted={true}
            loop={true}
            playsInline={true}
          >
            <source src={'https://instyle-investments.b-cdn.net/rizz%20cut%202(2).mp4'} />
            {/* <source src={'render.mp4'} /> */}
          </video>


          <motion.div className="absolute z-20 top-0 text-white ">
            <div className=" flex flex-col justify-end w-screen h-screen  bg-clip-content bg-gradient-to-r from-black/50 to-black/50">
              <motion.div className='px-2 pl-4 md:pl-12 py-10 pb-28'>
                <h2 className="h2">Introducing Rizz Suites</h2>
                <p className='mt-6 max-w-xl'>
                  An fascinating resort located in Sosúa, near the famous "Playa Alica".
                  <br />
                  <br />
                  Offering 27 luxury condos, built around pool area with a jacuzzi, water sunbeds, and a well-equipped bar serving delicious snacks.
                </p>
                <div className="grid grid-cols-3 md:flex gap-y-4 gap-x-2 md:gap-4 max-w-xs md:max-w-none mt-12 text-center">
                  {
                    amenities.map((amenity, index) => {
                      return (
                        <div style={{ transitionDelay: `${index * 150}ms` }} className={`w-20 ${isIntersecting ? 'shown-feature' : 'hidden-feature'}`} key={`amenities-${index}`}>
                          <div className='flex justify-center'>
                            <div className="rounded-full p-2 border-2 border-teracotta-500">
                              <amenity.icon className='text-teracotta-500 h-8 w-8' />
                            </div>
                          </div>
                          <div className='text-sm mt-2'>
                            {amenity.label}
                          </div>
                        </div>
                      )
                    })
                  }

                </div>
              </motion.div>
            </div>

          </motion.div>
        </motion.div>

      </div>

    </section >
  )
}
