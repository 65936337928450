import React from 'react'
import ROICalculator from './ROICalculator'
import { HiArrowRight, HiCheckCircle } from 'react-icons/hi2'

const bulletPoints = [
    {
        title: 'Fully managed Apartments',
        description: 'All apartments are part of a hotel complex. You receive 70% of the generated profit, minus a small fixed monthly fee. Costs of wear and tear are covered.'
    },
    {
        title: 'Perfect vacation home',
        description: 'Stay in your cozy escape from reality with all the comforts of home, free of charge.'
    },
    {
        title: 'German Property Developer',
        description: 'The property has been developed by a German company with more than 20 years of experience.'
    },
    {
        title: 'Experienced Operator',
        description: (
            <span>
                The Hotel is operated by Instyle Residences, which manages Infiniti Blu, a large resort in walking distance. {' '} <br />
                <a className='underline text-teracotta-700' target='_blank' href="https://infiniti-blu.com/infiniti-blu/">More about Infiniti Blu</a>
            </span>
        )
    },
    {
        title: 'Generously Insured',
        description: 'The property is insured by the Hotel. A copy of the insurance policy is available on request.'
    },
    {
        title: 'Full ownership',
        description: 'You own the apartment and can sell it at any time. Your ownership is proven through a legal document ("Title").'
    },
]

export default function BusinessModel() {
    return (
        <div className='relative overflow-hidden py-20'>
            <div className="absolute -top-12 " id="businessModel"></div>
            <div className="container ">
                <div className="max-w-3xl ">
                    <h2 className=" h2 tracking-tight sm:text-6xl font-trajanPro z-[20]">
                        Key investment insights
                    </h2>
                    {/* <div className="mt-6 p leading-8 ">
                    </div> */}
                </div>
                <div className="mt-10 flex gap-10 flex-col lg:flex-row">
                    <ROICalculator />
                    <div className=" ">
                        <ul className='flex flex-col md:grid grid-cols-2 gap-x-10 gap-y-8'>
                            {bulletPoints.map((el, index) => (
                                <li key={`bulletpoints-${index}`}>
                                    <div className='flex items-center'>
                                        <HiCheckCircle className='h-8 w-8 mr-2 text-teracotta-500' />
                                        <span className='text-lg font-medium'>{el.title}</span>
                                    </div>
                                    <div className='text-gray-700 ml-10 text-sm'>{el.description}</div>
                                </li>
                            ))}
                        </ul>
                        <div className='mt-6 ml-10'>
                            <button onClick={() => {
                                const el = document.getElementById('ctaExpose')
                                el.scrollIntoView({ behavior: 'smooth' })
                            }} className="btn-primary flex gap-2 items-center">
                                <span>
                                    Request more Info
                                </span>
                                <HiArrowRight className="ml-2 h-4 w-4" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
