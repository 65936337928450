import StructuredData from '@/components/StructuredData'
import { Disclosure } from '@headlessui/react'
import { HiMinusSmall, HiPlusSmall } from 'react-icons/hi2'

const faqs =
  [
    {
      "question": "What are the current real estate investment opportunities in the Dominican Republic?",
      "answer": "There are currently several investment opportunities in the Caribbean property market, particularly in the Dominican Republic. These include luxury villas, beachfront condos and commercial properties. Due to the growing tourism industry and the relatively low cost of living, the Dominican Republic is a popular destination for property investment."
    },
    {
      "question": "What are the key factors when investing in real estate in the Dominican Republic?",
      "answer": "There are several factors to consider when investing in property in the Dominican Republic. These include the location of the property, the condition of the property, the legal requirements and the potential return on investment. Working with a reputable estate agent and lawyer can ensure a safe and easy transaction."
    },
    {
      "question": "What are the legal requirements and regulations for foreign investors in the Dominican Republic?",
      "answer": 'Foreign investors wishing to purchase property in the Dominican Republic must obtain a "Compra de Bienes Inmuebles" (CBI) certificate from the Ministry of Tourism. They must also have a registered tax number and pay transfer taxes and legal fees to complete the purchase process.'
    },
    {
      "question": "How is the economic and political stability in the Dominican Republic?",
      "answer": "The Dominican Republic's economy is growing steadily and there are positive developments in the country's political stability. These factors have a positive impact on the property market and offer attractive investment opportunities."
    },
    {
      "question": "What are the popular regions or cities in the Dominican Republic for real estate investments?",
      "answer": "Popular locations for property investment in the Dominican Republic include Punta Cana, Santo Domingo and Cabarete. The proximity to beaches, tourist attractions and amenities such as shopping centres and restaurants make these locations attractive."
    },
    {
      "question": "What types of properties are available for investment in the Dominican Republic?",
      "answer": "There are various types of property available for investment in the Dominican Republic, including residential, commercial and holiday homes."
    },
    {
      "question": "What are the typical costs associated with buying and owning real estate in the Dominican Republic?",
      "answer": "Typical costs associated with buying and owning property in the Dominican Republic include property taxes, maintenance costs and management fees."
    },
    {
      "question": "Are there any restrictions on renting out properties to tourists or operating vacation rentals in the Dominican Republic?",
      "answer": "There are no restrictions on renting property to tourists or operating holiday homes in the Dominican Republic."
    },
    {
      "question": "What is the potential for return on investment (ROI) in the Dominican Republic real estate market?",
      "answer": "The potential for ROI in the Dominican Republic property market is relatively high, with a promising tourism industry, low property prices and a growing economy. However, factors such as location, property type and market conditions can affect ROI."
    },
    {
      "question": "Are there any specific risks associated with investing in real estate in the Dominican Republic?",
      "answer": "Specific risks and challenges associated with property investment in the Dominican Republic include property scams, inadequate property documentation and problems with property titles. Working with reputable agents and lawyers can help to mitigate these risks."
    }
  ]



const structuredData = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": faqs.map((el) => {
    return {
      "@type": "Question",
      "name": el.question,
      "acceptedAnswer": { "@type": "Answer", "text": el.answer }
    }
  })
}

export default function FAQ() {
  return (
    <>
      <StructuredData data={structuredData} />
      <div className="bg-white py-20">
        <div className="container divide-y divide-gray-900/10">
          <div className="max-w-2xl xl:col-span-2">
            <h2 className=" h1 tracking-tight sm:text-6xl font-trajanPro">
              FAQ's
            </h2>
            {/* <div className="mt-6 p leading-8 ">
                        27 freshly develped Luxury Apartments. <br /> Fully managed, inside a Hotel Complex. <br />
                        The perfect hands-off investment.
                    </div> */}
          </div>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <HiMinusSmall className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <HiPlusSmall className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </>

  )
}
