import alex from '@/assets/img/team/alexander-maurerbauer.jpeg'
import justian from '@/assets/img/team/justian-hanke.jpeg'
import helmut from '@/assets/img/team/helmut-maurerbauer.jpeg'
import Image from 'next/image'

const people = [
    {
        name: 'Alexander Maurerbauer',
        role: 'Sales Executive',
        image: alex,
        bio: ' A dedicated and ambitious young professional with a degree in Hotel and Business Management, seeking to leverage his knowledge and practical experience to contribute effectively to the growth and success of the family business.',
        twitterUrl: '#',
        linkedinUrl: 'https://www.linkedin.com/in/alexander-maurerbauer-047378285',
    },
    {
        name: 'Justian Hanke',
        role: 'Sales Representative',
        image: justian,
        bio: 'Having discovered his passion for selling at the age of 17, Justian has already gained considerable experience. He quickly realized that his interests and skills were especially suited to real estate.',
        twitterUrl: '#',
        linkedinUrl: 'https://www.linkedin.com/in/justian-hanke-ab1328283/',
    },
    {
        name: 'Helmut Maurerbauer',
        role: 'CEO',
        image: helmut,
        bio: 'Helmut Maurerbauer is an experienced hotel and tourism expert from Vienna, Austria. He has been in the industry for more than 30 years and developed one of the largest projects in the Dominican Republic in the 1990s. As a developer, he has thus contributed significantly to the upswing of local tourism.',
        twitterUrl: '#',
        linkedinUrl: 'https://www.linkedin.com/in/helmut-maurerbauer-47221981',
        classInject: 'col-span-2 flex justify-center',
        classInject2: 'max-w-xl'
    },
    // More people...
]

export default function Team() {
    return (
        <div className="bg-white py-20">
            <div className="container">
                <div className="max-w-2xl xl:col-span-2">
                    <h2 className="text-gray-800 h1 tracking-tight sm:text-6xl font-trajanPro">
                        About Instyle Investments
                    </h2>
                    {/* <div className="mt-6 p leading-8 ">
                        27 freshly develped Luxury Apartments. <br /> Fully managed, inside a Hotel Complex. <br />
                        The perfect hands-off investment.
                    </div> */}
                </div>
                <ul role="list" className={`flex-row md:grid md:grid-cols-2 gap-20`}>
                    {people.map((person) => (
                        <li className={`${person.classInject}`} key={`person-${person.name}`}>
                            <div className={`flex flex-col gap-10 pt-12 sm:flex-row ${person.classInject2}`}>
                                <Image src={person.image} className="aspect-[4/5] w-32 h-32 rounded-full flex-none object-cover" alt={`image ${person.name}`} />
                                <div className="max-w-xl flex-auto">
                                    <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                                    <p className="text-base leading-7 text-gray-600">{person.role}</p>
                                    <p className="mt-6 text-base leading-7 text-gray-600">{person.bio}</p>
                                    <ul role="list" className="mt-6 flex gap-x-6">
                                        {/* <li>
                                        <a href={person.twitterUrl} className="text-gray-400 hover:text-gray-500">
                                            <span className="sr-only">Twitter</span>
                                            <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                            </svg>
                                        </a>
                                    </li> */}
                                        <li>
                                            <a href={person.linkedinUrl} target='_blank' rel='noopener noreferrer' className="text-gray-400 hover:text-gray-500">
                                                <span className="sr-only">LinkedIn</span>
                                                <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>

                    ))}
                </ul>
            </div>
        </div>
    )
}
