import Map from "@/components/Map";
import React from "react";
import {
  MdLocalAirport,
  MdDirectionsCar,
  MdDirectionsWalk,
  MdBeachAccess,
  MdOutlineRestaurant
} from "react-icons/md";
import Overview from "@/assets/overview.jpeg"
import Image from "next/image";
import { HiArrowRight } from "react-icons/hi2";

import clear_water from "@/assets/img/clear_water.jpg";
import nature_1 from "@/assets/img/nature-1.png";
import playa_alicia from "@/assets/img/playa-alicia.jpg";

import { HiCheck } from "react-icons/hi2";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const steps = [
  {
    name: "Crystal Clear Water",
    description: "The north of the island is known for its crystal clear water and beautiful beaches.",
    image: clear_water,
  },
  {
    name: "Sprawling Beach Community",
    description: "Sosua is a small beach town in the north of the Dominican Republic.",
    image: nature_1,
  },
  {
    name: "Beautiful Beaches",
    description: "Playa Alicia is only one of the many beautiful beaches close by.",
    image: playa_alicia,
  },
];


export default function Location() {
  return (
    <div id="location" className="bg-white pb-16 w-screen overflow-hidden">
      <div
        className="relative py-16"
        style={{
          clipPath: 'inset(0 0 0 0)',
        }}
      >

        <div className="flex flex-col lg:flex-row items-center mt-12 w-screen lg:w-[105vw] lg:ml-[-2.5vw]">
          {steps.map((step, stepIdx) => {
            return (
              <div className="group width-trans" key={`substep-${stepIdx}`}>
                <div
                  className={`flex justify-center items-end shadow-xl relative ${step.status === "current"
                    ? "border-teracotta-500 border-8 lg:h-[30rem] h-[30rem] z-[10]"
                    : "lg:h-[28rem] h-[20rem]"
                    }  -skew-y-6 lg:-skew-y-0 lg:-skew-x-6  h-trans overflow-hidden bg-red-100 w-screen hover:z-[12] lg:w-auto hover:scale-105 duration-300 transform`}
                >
                  <>
                    <Image
                      className="skew-x-6 fill-skew hidden lg:block"
                      style={{ objectFit: "cover" }}
                      src={step.image}
                      alt="progress update of the project"
                    />
                    <Image
                      className="skew-y-6 fill-skew-sm lg:hidden"
                      style={{ objectFit: "cover" }}
                      src={step.image}
                      alt="progress update of the project"
                    /></>
                  <div className={`absolute w-full h-[40%] bottom-0 left-0 bg-gradient-to-t from-black/70`}></div>


                  <div className="lg:skew-y-0 skew-y-6 lg:skew-x-6 text-white pb-4">
                    <div className=" relative flex flex-col items-center justify-center flex-1">

                      <button
                        onClick={() => setCurrentStep(stepIdx)}
                        className={`ml-4 flex min-w-0 flex-col items-center duration-300 rounded-lg px-4 py-2 transform max-w-[95%]`}
                      >
                        <span className="text-base font-medium text-xl text-gray-200">
                          {step.name}
                        </span>
                        <span className="text-base text-small text-white mx-8">
                          {step.description}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>


              </div>
            );
          })}
        </div>

      </div>
      <div className="container text-gray-800  lg:flex lg:flex-row flex-col py-10 gap-12">
        <div className="relative h-80 lg:h-auto rounded overflow-hidden shadow-lg my-8 flex-1 ">
          <Map ></Map>

        </div>
        <div className="flex flex-col flex-1">

          <div className="pt-8 text-4xl font-trajanPro text-teracotta-600">close to some of the worlds most beautiful beaches</div>
          <div className="py-8 leading-relaxed text-gray-800 text-lg">
            The North Coast of the Dominican Republic is one of the most touristic and important areas of the
            Cibao region. Along its entire coastline, which ranges from Las Galeras, on the Samana peninsula, to
            Monte Cristi,there are various cities and towns that offer visitors the best options for leisure,
            adventure and nature.
          </div>
          <div>
            <button onClick={() => {
              const el = document.getElementById('ctaExpose')
              el.scrollIntoView({ behavior: 'smooth' })
            }} className="btn-primary flex gap-2 items-center">
              <span>
                Request more info
              </span>
              <HiArrowRight className="ml-2 h-4 w-4" />
            </button>
          </div>
          <div className="grid lg:grid-cols-2 flex-1 my-8 gap-4">

            <div>
              <div className="flex flex-row border border-teracotta-500 p-2 rounded items-center justify-between bg-white">
                <MdLocalAirport className="w-10 h-10 text-teracotta-600"></MdLocalAirport>
                <div className="flex flex-col items-end">
                  <div className="text-xl font-bold">Airport</div>
                  <div className="flex flex-row items-center gap-2">
                    <MdDirectionsCar></MdDirectionsCar>
                    <div>18 min</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex flex-row border border-teracotta-500 p-2 rounded items-center justify-between bg-white">
                <MdBeachAccess className="w-10 h-10 text-teracotta-600"></MdBeachAccess>
                <div className="flex flex-col items-end">
                  <div className="text-xl font-bold">Playa Alicia</div>
                  <div className="flex flex-row items-center gap-2">
                    <MdDirectionsWalk></MdDirectionsWalk>
                    <div>9 min</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex flex-row border border-teracotta-500 p-2 rounded items-center justify-between bg-white">
                <MdBeachAccess className="w-10 h-10 text-teracotta-600"></MdBeachAccess>
                <div className="flex flex-col items-end">
                  <div className="text-xl font-bold">Closest Beach</div>
                  <div className="flex flex-row items-center gap-2">
                    <MdDirectionsWalk></MdDirectionsWalk>
                    <div>4 min</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex flex-row border border-teracotta-500 p-2 rounded items-center justify-between bg-white">
                <MdOutlineRestaurant className="w-10 h-10 text-teracotta-600"></MdOutlineRestaurant>
                <div className="flex flex-col items-end">
                  <div className="text-xl font-bold">10+ Restaurants</div>
                  <div className="flex flex-row items-center gap-2">
                    <MdDirectionsWalk></MdDirectionsWalk>
                    <div>less than 10 min</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
