import React, { useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import logoWhite from '@/assets/logo-white.png'
import { HiOutlineArrowRight, HiOutlineChevronDoubleDown, HiOutlineChevronDown } from 'react-icons/hi2'
import { motion, useScroll, useTransform } from 'framer-motion'
import { useMediaQuery } from 'react-responsive'
import { useSearchParams } from 'next/navigation'

export default function Hero() {

    const containerRef = useRef(null)
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ['start end', 'end start']
    })
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
    const yMove = useTransform(scrollYProgress, [0, 0.5, 1], ['0%', '0%', '50%'])

    const searchParams = useSearchParams()



    // IOS Autoplay workaround
    useEffect(() => {
        window.addEventListener("touchstart", function () {
            var heroVideo = document.getElementById('heroVideo');
            heroVideo.play();
        }, { once: true });


    }, [])

    return (
        <div ref={containerRef} id='hero' className="relative">

            <motion.div style={{ y: isMobile ? 0 : yMove }} className={`h-screen w-screen absolute -z-10 bg-black bg-opacity-40 animate-fadeDown`}></motion.div>
            {/* <div className={`h-screen w-screen absolute -z-10 bg-gradient-to-${overlayStyle.substring(0, 1)} from-transparent to-black/${overlayOpacity}`}></div> */}

            <motion.div style={{ y: isMobile ? 0 : yMove }} className="h-screen w-screen absolute overflow-hidden object-cover -z-20 animate-fadeDown">
                <video
                    id='heroVideo'
                    className={'h-screen w-screen absolute overflow-hidden object-cover -z-20'}
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    playsInline={true}
                >
                    {/* <source src={'hero.mp4'} /> */}
                    <source src={'https://instyle-investments.b-cdn.net/hero-2.mp4'} />
                    {/* <source src={'https://res.cloudinary.com/dntwnklls/video/upload/f_auto:video,q_auto/v1/rizz-website/hero'} /> */}
                </video>
            </motion.div>
            <div className="h-screen w-screen flex flex-col-reverse">

                <div className="container mt-16 md:mt-0 pt-10 pb-24 sm:pb-32 flex flex-col md:flex-row space-y-4 md:space-y-0 md:py-40 md:px-12">
                    <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
                        {/* <Image className='w-64' src={logoWhite}></Image> */}
                        <h2 className="mt-10 h1 tracking-tight text-white">
                            {searchParams.get("t") ? searchParams.get("t") : "The perfect hands-off investment"}
                        </h2>
                        <div className="mt-6 p leading-8 text-white">
                            Invest in a brand new, fully managed luxury apartment in the heart of the caribbean islands.
                            Profit from the booming tourism industry and enjoy an expected return of 8% per year.
                        </div>

                        <div className="flex mt-6">
                            <button onClick={() => {
                                document.getElementById('units').scrollIntoView({ behavior: 'smooth' })
                            }} className="btn-primary flex items-center group bg-black/20">
                                Starting from $117,000
                                <HiOutlineArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transform duration-300" />
                            </button>
                        </div>

                    </div>
                </div>
            </div>

            <div className="absolute bottom-12 mx-auto left-0 right-0">
                <HiOutlineChevronDown className=" text-white text-4xl mx-auto" />
            </div>

        </div>
    )
}
